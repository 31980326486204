:root {
    --font-size: 1.25vw;
    --main-max-width: 1920px;
}

html {
    height: 100%;
}

body {
    height: 100%;
    min-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    color: #fff;
    font: var(--font-size) Arial, sans-serif;
    background-color: #191919;
}

wbr {
    width: 5px;
    display: inline-block;
}

* {
    outline: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-moz-placeholder {
    color: #b3b3b3;
    opacity: 1;
}

input::-webkit-input-placeholder {
    color: #b3b3b3;
}

input:-ms-input-placeholder {
    color: #b3b3b3;
}

input::-ms-input-placeholder {
    color: #b3b3b3;
}

input::placeholder {
    color: #b3b3b3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0.5208vw 0;
    font: calc(var(--font-size) * 1.25) Arial, sans-serif;
}

a {
    color: #9a9a9a;
    text-decoration: none;
}

a:hover,
a:active {
    color: #fff;
}

a.readonly {
    cursor: default;
    pointer-events: none;
}

.hidden,
.hide {
    display: none !important;
}

.visible {
    display: block !important;
}

.flex {
    display: flex;
}

.flex.vert_al_center {
    align-items: center;
}

.m0 {
    margin: 0 !important;
}

.p0 {
    padding: 0 !important;
}

.bd0 {
    border: 0 !important;
}

.bg_transparent {
    background-color: transparent !important;
}

.error,
.alert,
.alert-danger {
    display: block;
    margin: 0 0 0.6250vw;
    color: #e74c3c;
    font: var(--font-size) Arial, sans-serif;
}

.center,
.centered {
    text-align: center;
}

.full_h {
    height: 100%;
}

.full_w {
    width: 100%;
}

.ico {
    height: 2.6042vw;
    width: 3.1250vw;
    fill: #9a9a9a;
    cursor: pointer;
}

.ico:hover,
.ico:active,
.ico.active {
    fill: #fff;
}

.ico a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ico svg {
    display: block;
}

.ico.folder_extract {
    height: 1.5625vw;
    width: 1.6667vw;
}

.ico.refresh {
    width: 4.2556vw;
}

.ico.search {
    height: 1.1979vw;
    width: 1.3021vw;
}

.ico.trash_can {
    height: 1.7708vw;
    width: 1.6667vw;
}

.ico.show_hide {
    height: 0.4167vw;
    width: 0.8333vw;
}

.ico.treatment {
    height: 2.5521vw;
    width: 2.5521vw;
}

.ico.before_after {
    height: 2.4479vw;
    width: 2.6563vw;
}

.ico.folder_open {
    height: 2.1354vw;
    width: 2.7083vw;
}

.ico.folder {
    height: 2.1354vw;
    width: 2.5000vw;
}

.ico.folder:hover,
.ico.folder:active {
    fill: #eb8426;
}

.ico.del {
    height: 0.9375vw;
    width: 0.9375vw;
}

.ico.roll_up {
    height: 2.6042vw;
    width: 2.5000vw;
}

.button,
.button a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font: var(--font-size) Arial, sans-serif;
    text-decoration: none;
    white-space: nowrap;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
}

.button.tape a.with_bg {
    height: 2.1354vw;
    width: 10.8333vw;
    color: #d1d1d1;
    background-color: #007b3c;
    border-radius: 0.2604vw;
}

.button.tape a.with_arrow {
    height: 2.1354vw;
    width: 10.8333vw;
    color: #d1d1d1;
}

.button.tape a.with_arrow svg {
    height: 0.8333vw;
    width: 0.8333vw;
    margin: 0 1.3021vw 0 0;
    fill: #8f8f8f;
}

.button.tape a.with_bg:hover,
.button.tape a.with_bg:active,
.button.tape a.with_bg.active {
    color: #fff;
    background-color: #018d45;
}

.button.tape a.with_arrow,
.button.tape.active a.with_bg {
    display: none;
}

.button.tape.active a.with_arrow {
    display: inline-flex;
}

.button.tape a.with_arrow:hover,
.button.tape a.with_arrow:active {
    color: #fff;
}

.button.tape a.with_arrow:hover svg,
.button.tape a.with_arrow:active svg {
    fill: #fff;
}

.button.big {
    height: 2.6042vw;
    padding: 0 1.3021vw;
    color: #d1d1d1;
    border-radius: 0.2604vw;
}

.button.dark_blue {
    background-color: #285261;
}

.button.dark_blue:hover,
.button.dark_blue:active,
.button.dark_blue.active {
    color: #fff;
    background-color: #20657f;
}

.button.dark_blue.plus {
    font-size: calc(var(--font-size) * 2.0833);
}

header.fixed {
    background-color: #2b2b2b;
    border-bottom: 1px solid #222;
}

.left header .holder:nth-child(3) .button {
    margin: 0 0 0 1.0417vw;
}

.left header .holder:nth-child(3) .button:first-child {
    margin: 0;
}

main {
    height: 100%;
    width: 100%;
    max-width: var(--main-max-width);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    background-color: #353535;
}

body.login main {
    justify-content: center;
}

body.login article {
    padding: 6.2500vw 0 0;
    text-align: center;
}

body.login p {
    margin: 0 0 1.9792vw;
}

body.login form {
    width: 31.4583vw;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.9792vw;
    border: 0.1042vw dashed #9a9a9a;
    box-sizing: border-box;
}

body.login form input {
    height: 2.1354vw;
    width: 100%;
    margin: 0 0 1.5104vw;
    padding: 0 1.0417vw;
    color: #fff;
    font: var(--font-size) Arial, sans-serif;
    background-color: #5a5a5a;
    border: 1px solid #848484;
    box-sizing: border-box;
}

body.login form input[type=submit] {
    width: 12.0313vw;
    margin: 0;
    background-color: #00a852;
    border: 0;
    border-radius: 1.0417vw;
}

body.login form input[type=submit]:hover,
body.login form input[type=submit]:active {
    background-color: #019649;
}

section.left,
section.right {
    height: 100%;
    display: flex;
    flex-direction: column;
}

section.left {
    width: 100%;
    max-width: 64.3229vw;
}

section.right {
    width: 35.6771vw;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
}

section.right::before {
    content: '';
    width: 0.1042vw;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #202020;
}

header.fixed {
    height: 8.2292vw;
    flex-shrink: 0;
}

.left header {
    display: flex;
    flex-direction: column;
    padding: 0.8333vw 2.0833vw 1.3542vw 2.6042vw;
    box-sizing: border-box;
}

.left header .holder {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.7292vw;
}

.left header .holder:nth-child(3) {
    justify-content: flex-start;
}

.left header .holder:last-child {
    margin: 0;
}

.left header .ico_holder {
    display: flex;
}

.left header .ico_holder .ico {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 1.5625vw;
}

.left header .ico_holder .ico:first-child {
    margin: 0;
}

.left header .holder:first-child .ico_holder .ico {
    margin: 0 0.4688vw 0 0;
}

.left header .ico_holder .ico svg {
    max-height: 100%;
    max-width: 100%;
    display: block;
}

.left header nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.left header nav ul li {
    margin: 0 0 0 2.0833vw;
}

.left header nav ul li:first-child {
    margin: 0;
}

.left header nav ul a {
    color: #fff;
    user-select: none;
}

.left header nav ul a.logo {
    text-transform: uppercase;
}

.left header nav ul a:hover,
.left header nav ul a:active {
    color: #ddd;
}

.left header nav select {
    height: 2.6042vw;
    width: 21.0938vw;
    display: block;
    padding: 0 0.9375vw;
    color: #fff;
    font: var(--font-size) Arial, sans-serif;
    background-color: #2f2f2f;
    border: 0.1042vw solid #636363;
}

.left header .flex.vert_al_center>div,
.left header .flex.vert_al_center>form {
    margin: 0 0 0 1.5625vw;
}

.left header .flex.vert_al_center>div:first-child,
.left header .flex.vert_al_center>form:first-child {
    margin: 0;
}

.search_form {
    height: 2.1354vw;
    width: 22.8125vw;
    display: flex;
    overflow: hidden;
    padding: 0 0.5208vw 0 1.0417vw;
    background-color: #494949;
    border-radius: 0.2604vw;
    box-sizing: border-box;
}

.search_form input {
    height: 2.1354vw;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
}

.search_form input[type=search] {
    width: 100%;
    color: #fff;
    font-size: calc(var(--font-size) * 0.8333);
}

.search_form input[type=submit] {
    width: 2.4479vw;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.77 22.9'%3E%3Cpath d='M24.77,20.62l-7.18-6.17A9.37,9.37,0,0,0,19,9.5a9.54,9.54,0,1,0-3.36,7.23l7.17,6.17ZM9.5,16A6.5,6.5,0,1,1,16,9.5,6.51,6.51,0,0,1,9.5,16Z' fill='%23a2a2a2' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1.3542vw auto;
    text-indent: 9999px;
    cursor: pointer;
}

.search_form input[type=submit]:hover,
.search_form input[type=submit]:active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.77 22.9'%3E%3Cpath d='M24.77,20.62l-7.18-6.17A9.37,9.37,0,0,0,19,9.5a9.54,9.54,0,1,0-3.36,7.23l7.17,6.17ZM9.5,16A6.5,6.5,0,1,1,16,9.5,6.51,6.51,0,0,1,9.5,16Z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
}

section.left .wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: 0.2604vw 0.7813vw 0.7813vw 0;
    padding: 0 2.0833vw 0.7813vw 2.7604vw;
    box-sizing: border-box;
}

section.left .wrapper ul.accordion {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
}

section.left .folder {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 3.5417vw 0 0;
    padding: 0;
    color: #aeaeae;
    border-top: 0.1042vw solid #202020;
    animation: flipdown 0.5s ease both;
}

section.left .folder:first-child {
    margin: 0.6771vw 0 0;
}

section.left .folder .title {
    height: 3.6458vw;
    display: flex;
    align-items: center;
}

section.left .folder .title span {
    margin: 0 0 0 1.3542vw;
}

section.left .folder .title span:first-child {
    margin: 0;
}

section.left .folder>.ico.trash_can {
    height: 1.5625vw;
    width: 1.4583vw;
    position: absolute;
    top: 1.0417vw;
    right: 4.6875vw;
    z-index: 3;
}

section.left .folder .ico.show_hide {
    height: 0.4167vw;
    width: 0.8333vw;
    position: absolute;
    top: 1.5625vw;
    right: 1.2500vw;
    overflow: hidden;
    transition: 0.4s;
}

section.left .wrapper li.folder input[type=checkbox]:checked~.ico.show_hide svg {
    transform: rotate(180deg);
}

section.left .wrapper li.folder .title {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

section.left .wrapper li.folder .content {
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    opacity: 1;
    transform: translate(0, 0);
    z-index: 2;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

section.left .wrapper li.folder input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 1;
    opacity: 0;
}

section.left .wrapper li.folder input[type=checkbox]:checked~.content {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translate(0, -10%);
}

section.left .folder .img_with_buttons {
    display: flex;
    margin: 2.8646vw 0 0;
}

section.left .folder .img_with_buttons:first-child {
    margin: 0;
}

section.left .folder .img_holder {
    height: 35.1042vw;
    width: 52.6563vw;
    display: flex;
    justify-content: center;
}

section.left .folder .img_holder img {
    height: 100%;
    max-width: 100%;
}

section.left .folder .buttons_holder {
    width: 4.3229vw;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 0 0.8333vw;
}

section.left .folder .buttons_holder>div {
    width: 100%;
}

section.left .folder .buttons_holder .trash_can {
    height: 2.7669vw;
    width: 2.6042vw;
    margin: 0 0 1.5625vw;
    fill: #6a6a6a;
}

section.left .folder .buttons_holder .trash_can:hover,
section.left .folder .buttons_holder .trash_can:active {
    fill: #fff;
}

section.left .folder .buttons_holder>div>.ico {
    margin: 1.1979vw auto 0;
}

section.left .folder .buttons_holder>div>.ico:first-child {
    margin: 0 auto;
}

section.left .folder .buttons_holder>div>.ico.treatment,
section.left .folder .buttons_holder>div>.ico.before_after,
section.left .folder .buttons_holder>div>.ico.folder_open {
    height: 4.0625vw;
    width: 4.3229vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4c;
    border-radius: 0.8333vw;
}

section.left .folder .buttons_holder>div>.ico.treatment>svg {
    height: 2.5521vw;
    width: 2.5521vw;
}

section.left .folder .buttons_holder>div>.ico.before_after>svg {
    height: 2.4479vw;
    width: 2.6563vw;
}

section.left .folder .buttons_holder>div>.ico.folder_open>svg {
    height: 2.1354vw;
    width: 2.7083vw;
}

section.right header {
    padding: 1.3542vw 2.0833vw;
    box-sizing: border-box;
}

section.right header .holder {
    display: flex;
    justify-content: space-between;
}

section.right .wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: 0 0.5208vw 0.7813vw 0;
    padding: 0 0.6771vw 0.7813vw 2.0833vw;
    box-sizing: border-box;
}

section .wrapper::-webkit-scrollbar {
    width: 0.8854vw;
}

section .wrapper::-webkit-scrollbar-track {
    border-radius: 0.4688vw;
}

section .wrapper::-webkit-scrollbar-thumb {
    background: #5b5b5b;
    border-radius: 0.5208vw;
}

section .wrapper::-webkit-scrollbar-thumb:hover {
    background: #5b5b5b;
}

section .wrapper .waiting {
    margin: 4.1667vw 0 0;
    font: calc(var(--font-size) * 1.25) Arial, sans-serif;
    text-align: center;
}

img {
    max-width: 100%;
    display: block;
}

.new_folder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1042vw dashed #aeaeae;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;
}

.new_folder.back .flex {
    align-items: center;
}

.new_folder.back .ico {
    height: 0.9375vw;
    width: 1.8750vw;
    margin: 0 1.5625vw 0 0;
    fill: #fff;
}

.new_folder:hover,
.new_folder:active,
.new_folder.active,
.new_folder.back:hover .ico,
.new_folder.back:active .ico,
.new_folder.back.active .ico {
    opacity: 1;
}

.new_folder p {
    margin: 0;
    font-size: calc(var(--font-size) * 0.7917);
}

.new_folder p.title {
    margin: 0 0 0.1563vw;
    font-size: calc(var(--font-size) * 1.4167);
}

.button.yellow,
.button.red,
.button.blue,
.button.green {
    height: 1.5104vw;
    padding: 0 0.5208vw;
    font-size: calc(var(--font-size) * 0.75);
    border-radius: 0.7813vw;
    opacity: 0.5;
}

.button.yellow.active,
.button.red.active,
.button.blue.active,
.button.green.active {
    opacity: 1;
}

.button.yellow,
.billet.yellow.active {
    color: #000;
    background-color: #f3d794;
}

.button.red,
.billet.red.active {
    color: #fff;
    background-color: #d74c4c;
}

.button.blue,
.billet.blue.active {
    color: #fff;
    background-color: #47b5b0;
}

.button.green,
.billet.green.active {
    color: #fff;
    background-color: #00a852;
}

.button.grey,
.billet.grey.active {
    color: #fff;
    background-color: #676767;
}

section.right .right_folder {
    height: 8.8542vw;
    width: 100%;
    display: flex;
    overflow: hidden;
    margin: 1.5625vw 0 0;
    border-radius: 0.6250vw;
    box-sizing: border-box;
}

section.right .right_folder:first-child {
    margin: 0.7292vw 0 0;
}

section.right .right_folder .img_holder {
    height: 100%;
    width: 8.8542vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    padding: 0.5208vw;
    background-color: #5a5a5a;
    box-sizing: border-box;
}

section.right .right_folder.active .img_holder {
    background-color: #b2b2b2;
}

section.right .right_folder .img_holder img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    border: 0.1042vw solid #fff;
}

section.right .right_folder .data_holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 1.0417vw;
    background-color: #707070;
}

section.right .right_folder.active .data_holder {
    background-color: #ddd;
}

section.right .right_folder .data_holder .form_and_ico {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.9896vw;
}

section.right .right_folder .data_holder input {
    height: 2.1354vw;
    width: 17.0313vw;
    margin: 0;
    padding: 0 0.7292vw;
    color: #fff;
    font: var(--font-size) Arial, sans-serif;
    background-color: #8f8f8f;
    border: 1px solid #acacac;
    box-sizing: border-box;
}

section.right .right_folder.active .data_holder input {
    color: #000;
    background-color: #f4f4f4;
    border: 1px solid #adadad;
}

section.right .right_folder.active .data_holder .form_and_ico .ico {
    fill: #ed882d;
}

section.right .right_folder .data_holder .info {
    display: flex;
    margin: 0 0 0.3646vw;
    font-size: calc(var(--font-size) * 0.6667);
}

section.right .right_folder .data_holder .info:last-child {
    margin: 0;
}

.billet {
    height: 1.5104vw;
    display: flex;
    align-items: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

section.right .right_folder .data_holder .info .billet.all {
    margin: 0 0 0 0.4167vw;
}

section.right .right_folder.active .data_holder .info .billet {
    color: #000;
}

.billet.red,
.billet.green,
.billet.yellow,
.billet.blue,
.billet.grey {
    padding: 0 0.4167vw;
    font-size: calc(var(--font-size) * 0.6667);
    border-radius: 0.7813vw;
}


section.left .small_photos.inside .folder .billet {
    margin: 0 0 0 0.6250vw;
    padding: 0 0.6250vw;
    font-size: calc(var(--font-size) * 0.7917);
}

section.left .small_photos.inside .folder .billet:first-child {
    margin: 0;
}

section.left .small_photos.inside .folder .billet span {
    margin: 0 0 0 0.2083vw;
}

section.right .right_folder .data_holder .info .billet.red,
section.right .right_folder .data_holder .info .billet.green {
    margin: 0 0 0 0.3125vw;
}

section.right .right_folder .data_holder .info .billet.yellow,
section.right .right_folder .data_holder .info .billet.blue {
    margin: 0;
}

.billet span {
    font-weight: bold;
    margin: 0 0 0 0.1563vw;
}

section.right .right_folder .ico.del {
    position: absolute;
    right: 1.0938vw;
    bottom: 0.6771vw;
    fill: #000;
}

section.right .right_folder .ico.del:hover,
section.right .right_folder .ico.del:active {
    fill: #fff;
}

section.right .right_folder.active .ico.del {
    fill: #444;
}

section.right .right_folder.active .ico.del:hover,
section.right .right_folder.active .ico.del:active {
    fill: #000;
}

section.right .right_folder .move_to_folder {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    color: #8f8f8f;
    font-size: calc(var(--font-size) * 0.75);
    background-color: #fff;
    border-radius: 0.5208vw;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

section.right .right_folder .move_to_folder span {
    width: 100%;
    text-align: center;
}

section.right .right_folder .move_to_folder span.big {
    font-size: calc(var(--font-size) * 3);
}

section.right .right_folder .move_to_folder span:last-child {
    margin: 0 0 0.5208vw;
}

section.left .accordion.small_photos .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

section.left .accordion.small_photos .content::after {
    content: '';
    width: 18.4375vw;
    display: flex;
}

section.left .accordion.small_photos .img_with_buttons {
    height: 18.4375vw;
    width: 18.4375vw;
    position: relative;
    margin: 0 0 1.2500vw;
}

section.left .accordion.small_photos .img_holder {
    height: 17.3958vw;
    width: 17.3958vw;
    position: absolute;
    top: 0.5208vw;
    left: 0.5208vw;
    z-index: 2;
    pointer-events: none;
}

section.left .accordion.small_photos .img_holder img {
    height: auto;
    max-height: 100%;
    object-fit: contain;
}

section.left .accordion.small_photos .buttons_holder {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    background-color: #2a2a2a;
    border: 0.1563vw solid #2a2a2a;
    border-radius: 0.6250vw;
    box-sizing: border-box;
    z-index: 1;
}

section.left .accordion.small_photos .yellow .buttons_holder {
    border: 0.1563vw solid #f3d794;
}

section.left .accordion.small_photos .red .buttons_holder {
    border: 0.1563vw solid #d74c4c;
}

section.left .accordion.small_photos .blue .buttons_holder {
    border: 0.1563vw solid #47b5b0;
}

section.left .accordion.small_photos .green .buttons_holder {
    border: 0.1563vw solid #00a852;
}

section.left .accordion.small_photos .img_with_buttons:hover .buttons_holder,
section.left .accordion.small_photos .img_with_buttons:active .buttons_holder,
section.left .accordion.small_photos .img_with_buttons.active .buttons_holder {
    background-color: #434343;
    border: 0.1563vw solid #717171;
}

section.left .accordion.small_photos .folder .buttons_holder>div>.ico.treatment,
section.left .accordion.small_photos .folder .buttons_holder>div>.ico.before_after,
section.left .accordion.small_photos .folder .buttons_holder>div>.ico.folder_open {
    height: 3.2813vw;
    width: 3.2813vw;
    position: absolute;
    margin: 0;
    background-color: transparent;
    border-radius: 0;
}

section.left .accordion.small_photos .folder .buttons_holder>div>.ico.treatment {
    top: 0;
    left: 0;
}

section.left .accordion.small_photos .folder .buttons_holder>div>.ico.before_after {
    top: 0;
    right: 0;
}

section.left .accordion.small_photos .folder .buttons_holder>div>.ico.folder_open {
    right: 0;
    bottom: 0;
}

section.left .accordion.small_photos .folder .buttons_holder .trash_can,
section.left .accordion.small_photos .folder .buttons_holder .back {
    height: 3.2813vw;
    width: 3.2813vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    margin: 0;
    fill: #9a9a9a;
}

section.left .accordion.small_photos .folder .buttons_holder .trash_can:hover,
section.left .accordion.small_photos .folder .buttons_holder .trash_can:active,
section.left .accordion.small_photos .folder .buttons_holder .back:hover,
section.left .accordion.small_photos .folder .buttons_holder .back:active {
    fill: #fff;
}

section.left .accordion.small_photos .folder .buttons_holder>div>.ico.treatment svg,
section.left .accordion.small_photos .folder .buttons_holder>div>.ico.before_after svg,
section.left .accordion.small_photos .folder .buttons_holder>div>.ico.folder_open svg,
section.left .accordion.small_photos .folder .buttons_holder .trash_can svg {
    height: 1.9271vw;
}

section.left .accordion.small_photos .folder .buttons_holder .back svg {
    height: 1.9792vw;
    width: 1.9271vw;
}



@media all and (min-width: 1920px) {

    :root {
        --font-size: 24px;
    }

    body {
        font: 24px Arial, sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 10px 0;
    }

    .error,
    .alert,
    .alert-danger {
        margin: 0 0 12px;
    }

    .ico {
        height: 50px;
        width: 60px;
    }

    .ico.folder_extract {
        height: 30px;
        width: 32px;
    }

    .ico.refresh {
        width: 81.7073px;
    }

    .ico.question {
        width: 24px;
    }

    .ico.search {
        height: 23px;
        width: 25px;
    }

    .ico.trash_can {
        height: 34px;
        width: 32px;
        top: 17px;
        right: 90px;
    }

    .ico.show_hide {
        height: 8px;
        width: 16px;
    }

    .ico.treatment {
        height: 49px;
        width: 49px;
    }

    .ico.before_after {
        height: 47px;
        width: 51px;
    }

    .ico.folder_open {
        height: 41px;
        width: 52px;
    }

    .ico.folder {
        height: 41px;
        width: 48px;
    }

    .ico.del {
        height: 18px;
        width: 18px;
    }

    .ico.roll_up {
        height: 50px;
        width: 48px;
    }

    .button.tape a.with_bg {
        height: 41px;
        width: 208px;
        border-radius: 5px;
    }

    .button.tape a.with_arrow {
        height: 41px;
        width: 208px;
    }

    .button.tape a.with_arrow svg {
        height: 16px;
        width: 16px;
        margin: 0 25px 0 0;
    }

    .search_form input[type=search] {
        font-size: 20px;
    }

    .left header .holder:nth-child(3) .button {
        margin: 0 0 0 20px;
    }

    .button.big {
        height: 50px;
        padding: 0 25px;
        border-radius: 5px;
    }

    section.right {
        width: 685px;
    }

    section.right::before {
        width: 2px;
    }

    header.fixed {
        height: 158px;
    }

    .left header {
        padding: 16px 40px 26px 50px;
    }

    .left header .holder {
        margin: 0 0 14px;
    }

    .left header .ico_holder .ico {
        margin: 0 0 0 30px;
    }

    .left header .holder:first-child .ico_holder .ico {
        margin: 0 9px 0 0;
    }

    .left header nav ul li {
        margin: 0 0 0 40px;
    }

    .left header nav select {
        height: 50px;
        width: 405px;
        padding: 0 18px;
        border: 2px solid #636363;
    }

    .left header .flex.vert_al_center>div,
    .left header .flex.vert_al_center>form {
        margin: 0 0 0 30px;
    }

    .button.dark_blue.plus {
        font-size: 50px;
    }

    .search_form {
        height: 41px;
        width: 438px;
        padding: 0 10px 0 20px;
        border-radius: 5px;
    }

    .search_form input {
        height: 41px;
    }

    .search_form input[type=submit] {
        width: 47px;
        background-size: 26px auto;
    }

    section.left {
        max-width: 1235px;
    }

    section.left .wrapper {
        margin: 5px 15px 15px 0;
        padding: 0 40px 15px 53px;
    }

    section .wrapper::-webkit-scrollbar {
        width: 17px;
    }

    section .wrapper::-webkit-scrollbar-track {
        border-radius: 9px;
    }

    section .wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    section .wrapper .waiting {
        margin: 80px 0 0;
        font: 30px Arial, sans-serif;
    }

    section.left .folder {
        margin: 68px 0 0;
        border-top: 2px solid #202020;
    }

    section.left .folder:first-child {
        margin: 13px 0 0;
    }

    section.left .folder .title {
        height: 70px;
    }

    section.left .folder .title span {
        margin: 0 0 0 26px;
    }

    section.left .folder>.ico.trash_can {
        height: 30px;
        width: 28px;
        top: 20px;
    }

    section.left .folder .ico.show_hide {
        top: 30px;
        right: 24px;
    }

    section.left .folder .img_with_buttons {
        margin: 55px 0 0;
    }

    section.left .folder .img_holder {
        height: 674px;
        width: 1011px;
    }

    section.left .folder .buttons_holder {
        width: 83px;
        margin: 0 0 0 16px;
    }

    section.left .folder .buttons_holder .trash_can {
        height: 53.125px;
        width: 50px;
        margin: 0 0 30px;
    }

    section.left .folder .buttons_holder>div>.ico {
        margin: 23px auto 0;
    }

    section.left .folder .buttons_holder>div>.ico:first-child {
        margin: 0 auto;
    }

    section.left .folder .buttons_holder>div>.ico.treatment,
    section.left .folder .buttons_holder>div>.ico.before_after,
    section.left .folder .buttons_holder>div>.ico.folder_open {
        height: 78px;
        width: 83px;
        border-radius: 16px;
    }

    section.left .folder .buttons_holder>div>.ico.treatment>svg {
        height: 49px;
        width: 49px;
    }

    section.left .folder .buttons_holder>div>.ico.before_after>svg {
        height: 47px;
        width: 51px;
    }

    section.left .folder .buttons_holder>div>.ico.folder_open>svg {
        height: 41px;
        width: 52px;
    }

    section.right header {
        padding: 26px 40px;
    }

    section.right .wrapper {
        margin: 0 10px 15px 0;
        padding: 0 13px 15px 40px;
    }

    .new_folder {
        border: 2px dashed #aeaeae;
    }

    .new_folder.back .ico {
        height: 18px;
        width: 36px;
        margin: 0 30px 0 0;
    }

    .new_folder p.title {
        margin: 0 0 3px;
    }

    .button.yellow,
    .button.red,
    .button.blue,
    .button.green {
        height: 29px;
        padding: 0 10px;
        border-radius: 15px;
    }

    section.right .right_folder {
        height: 170px;
        margin: 30px 0 0;
        border-radius: 12px;
    }

    section.right .right_folder:first-child {
        margin: 14px 0 0;
    }

    section.right .right_folder .img_holder {
        width: 170px;
        padding: 10px;
    }

    section.right .right_folder .img_holder img {
        border: 2px solid #fff;
    }

    section.right .right_folder .data_holder {
        padding: 20px;
    }

    section.right .right_folder .data_holder .form_and_ico {
        margin: 0 0 19px;
    }

    section.right .right_folder .data_holder input {
        height: 41px;
        width: 327px;
        padding: 0 14px;
    }

    section.right .right_folder .data_holder .info {
        margin: 0 0 7px;
    }

    .billet.red,
    .billet.green,
    .billet.yellow,
    .billet.blue,
    .billet.grey {
        padding: 0 8px;
        border-radius: 15px;
    }

    .small_photos.inside .billet {
        padding: 0 12px;
    }

    section.left .small_photos.inside .folder .billet {
        margin: 0 0 0 12px;
    }

    section.left .small_photos.inside .folder .billet span {
        margin: 0 0 0 4px;
    }

    .billet {
        height: 29px;
    }

    section.right .right_folder .data_holder .info .billet.all {
        margin: 0 0 0 8px;
    }

    section.right .right_folder .data_holder .info .billet.red,
    section.right .right_folder .data_holder .info .billet.green {
        margin: 0 0 0 6px;
    }

    .billet span {
        margin: 0 0 0 3px;
    }

    section.right .right_folder .ico.del {
        right: 21px;
        bottom: 13px;
    }

    section.right .right_folder .move_to_folder {
        border-radius: 10px;
    }

    section.right .right_folder .move_to_folder span:last-child {
        margin: 0 0 10px;
    }

    section.left .accordion.small_photos .content::after {
        width: 354px;
    }

    section.left .accordion.small_photos .img_with_buttons {
        height: 354px;
        width: 354px;
        margin: 0 0 24px;
    }

    section.left .accordion.small_photos .img_holder {
        height: 334px;
        width: 334px;
        top: 10px;
        left: 10px;
    }

    section.left .accordion.small_photos .buttons_holder {
        border: 3px solid #2a2a2a;
        border-radius: 12px;
    }

    section.left .accordion.small_photos .yellow .buttons_holder {
        border: 3px solid #f3d794;
    }

    section.left .accordion.small_photos .red .buttons_holder {
        border: 3px solid #d74c4c;
    }

    section.left .accordion.small_photos .blue .buttons_holder {
        border: 3px solid #47b5b0;
    }

    section.left .accordion.small_photos .green .buttons_holder {
        border: 3px solid #00a852;
    }

    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.treatment,
    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.before_after,
    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.folder_open,
    section.left .accordion.small_photos .folder .buttons_holder .trash_can,
    section.left .accordion.small_photos .folder .buttons_holder .back {
        height: 63px;
        width: 63px;
    }

    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.treatment svg,
    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.before_after svg,
    section.left .accordion.small_photos .folder .buttons_holder>div>.ico.folder_open svg,
    section.left .accordion.small_photos .folder .buttons_holder .trash_can svg {
        height: 37px;
    }

    section.left .accordion.small_photos .folder .buttons_holder .back svg {
        height: 38px;
        width: 37px;
    }

    body.login article {
        padding: 120px 0 0;
    }

    body.login p {
        margin: 0 0 38px;
    }

    body.login form {
        width: 604px;
        padding: 38px;
        border: 2px dashed #9a9a9a;
    }

    body.login form input {
        height: 41px;
        margin: 0 0 29px;
        padding: 0 20px;
    }

    body.login form input[type=submit] {
        width: 231px;
        border-radius: 20px;
    }
}

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}