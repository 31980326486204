.img_with_buttons svg[data-act="folder"] {
    display: none;
}

#rightWrapper .itemFolder.priority .data_holder {
    background: #60594f;
}

#rightWrapper .itemFolder.priority .ico.folder {
    fill: #eb8426;
}

#rightWrapper .itemFolder.drop .data_holder {
    background: #006578 !important;
}

svg[data-opt] {
    display: none;
}